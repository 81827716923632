<template>
  <div class="report-card-box" @click="read(url)">
    <div class="report-date">
      <div>
        <img src="@/assets/image/btcinfo/report/pdf.png" alt="pdf">
        <div>{{ year }}</div>
      </div>
      <div>{{ date }}</div>
    </div>
    <div class="report-title">
      <div>
        {{ title }}
      </div>
    </div>
    <div class="report-download hand" @click.stop="download(url)">
      <img src="@/assets/image/btcinfo/report/download.png" alt="download">
      <div>下载</div>
    </div>
    <div class="report-read hand">
      <div>阅读</div>
      <div>></div>
    </div>
    <div v-if="data.BulletinID === 1000" class="free">Free</div>
  </div>
</template>

<script>
import commonmix from '@/utils/commonmix'

export default {
  mixins: [commonmix],
  name: 'CnBTCInfoReportCard',
  props: {
    data: {
      type: Object,
      default: () => {}
    },
    isEnExpire: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      title: '必孚简报'
    }
  },
  computed: {
    year () {
      return this.data.BulletinTitle.slice(5, 9)
    },
    date () {
      return this.data.BulletinTitle.slice(10, 21)
    },
    url () {
      return process.env.VUE_APP_STATIC + this.data.BulletinPath
    }
  },
  methods: {
    download (href) {
      if (this.$store.getters.token) {
        console.log(this.data)
        if (this.isEnExpire || this.data.BulletinID === 1000) {
          fetch(href).then((response) => {
            return response.blob()
          }).then((arraybuffer) => {
            const blob = new Blob([arraybuffer])
            const link = document.createElement('a')
            const fileName = new Date().getTime() + '.pdf'
            link.href = window.URL.createObjectURL(blob)
            link.download = fileName
            link.click()
            window.URL.revokeObjectURL(link.href)
          })
        } else {
          this.openCnMembershipModal()
        }
      } else {
        this.openCnLoginModal()
      }
    },
    read (url) {
      if (this.$store.getters.token) {
        if (this.isEnExpire || this.data.BulletinID === 1000) {
          window.open(url)
        } else {
          this.openCnMembershipModal()
        }
      } else {
        this.openCnLoginModal()
      }
    }
  }
}
</script>

<style lang="scss" scoped>

.report-card-box {
  width: 353px;
  height: 106px;
  border-radius: 8px;
  background-image:url(../../../../assets/image/btcinfo/report/background.png);
  background-repeat: no-repeat;
  background-size: cover;
  box-shadow: 0 0 5px 2px #e0e0e0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 70px 5px 10px;
  position: relative;
}

.report-date > div:first-child {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 0;
  font-size: 12px;
  font-weight: 400;
  transform: scale(.9);
}

.report-date > div:first-child > img {
  width: 12px;
  height: 16px;
  margin-right: 10px;
}

.report-date > div:last-child {
  font-size: 12px;
  font-weight: 400;
  transform: scale(.9);
}

.report-title {
  color: #014479;
  font-size: 16px;
  font-weight: 400;
}

.report-download {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #014479;
  font-size: 12px;
  position: absolute;
  bottom: 0;
  right: 90px;
  transform: scale(.9);
}

.report-download > img {
  width: 12px;
  height: 10px;
  margin-right: 5px;
}

.report-read {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #DF232A;
  font-size: 12px;
  position: absolute;
  bottom: 10px;
  right: 5px;
  transform: scale(.9);
}

.report-read > div:first-child {
  margin-right: 5px;
}

.free {
  position: absolute;
  font-size: 12px;
  background: #e32229;
  border-radius: 3px 8px 3px 3px;
  color: #ffffff;
  padding: 0 5px;
  top: 0;
  right: 0;
}
</style>
